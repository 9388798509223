import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageList from "../../Components/PageCard/PageList";
import { getPagesData } from "../../REDUX/actions/pages";
import i18next from "i18next";

const PagesView = () => {
  const dispatch = useDispatch();
  const FRONT_SITE_URL = "https://poseidon-spa.com";
  const { pagesLoading, pagesData } = useSelector(
    (state) => state.pagesReducer
  );
  useEffect(() => {
    if (!pagesData) {
      dispatch(getPagesData());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getPagesData());
    // eslint-disable-next-line
  }, [i18next.language]);
  return (
    <div>
      <PageList
        pagesLoading={pagesLoading}
        pagesData={pagesData}
        FRONT_SITE_URL={FRONT_SITE_URL}
      />
    </div>
  );
};

export default PagesView;
