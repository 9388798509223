import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "../Common/Switch";
import InputText from "../sectioninput/formelement/InputText";
import _ from "lodash";
import {
  collections,
  types,
  placesNumber,
  availability,
  yesOrNo,
  caracteristiqueInputs,
  equipmentsInputs,
  equipementSwitches,
  jetInputs,
  jetSwitches,
  saunaCaracteristiqueInputs,
  saunaEquipmentsInputs,
  saunaAccessorySwitches,
  saunaCollections,
  saunaInfrarouge,
  spaDeNageCollections,
  spaDeNageInputs,
} from "./productsData";
import { updateProduct } from "../../REDUX/actions/products";
import ProductSubmitButton from "./ProductSubmitButton";
import Uploader from "../Common/Uploader";
import i18next from "i18next";
import Lists from "../Lists/Lists";
import { Accordion, Form } from "react-bootstrap";
import { productUrlRewrite } from "../../utils/utils";
import RelatedProducts from "./RelatedProducts";

const ProductForm = ({ productItem }) => {
  const dispatch = useDispatch();

  const [productInputs, setproductInputs] = useState(
    productItem
      ? _.omit(productItem.attributes, [
          "locale",
          "colors",
          "pointfort",
          "versionImages",
          "relatedProducts",
          "cuveColors",
          "banner",
          "createdAt",
          "localizations",
          "updatedAt",
          "seo",
          "jetCard",
          "pointreassurance",
          "realisation",
          "jetList",
        ])
      : {}
  );

  const handleProductChange = (value, champs) => {
    setproductInputs({ ...productInputs, [champs]: value });
  };

  const [image, setimage] = useState(productItem.attributes.image.data);
  const [image2, setimage2] = useState(productItem.attributes.image2.data);
  const [technicalSheet, settechnicalSheet] = useState(productItem.attributes.ficheTechnique.data);
  const [relatedProducts, setrelatedProducts] = useState();

  const handleUpdateProduct = () => {
    dispatch(
      updateProduct(productItem.id, {
        ...productInputs,
        path: productUrlRewrite(productItem.id, productInputs.name),
        relatedProducts: relatedProducts,
        image: image.id,
        image2: image2.id,
        ficheTechnique: technicalSheet.id,
      })
    );
  };
  useEffect(() => {
    setproductInputs(
      productItem
        ? _.omit(productItem.attributes, [
            "locale",
            "colors",
            "pointfort",
            "versionImages",
            "relatedProducts",
            "cuveColors",
            "banner",
            "createdAt",
            "localizations",
            "updatedAt",
            "seo",
            "jetCard",
            "pointreassurance",
            "realisation",
            "jetList",
          ])
        : {}
    );

    setimage(productItem.attributes.image.data);
    setimage2(productItem.attributes.image2.data);
    // eslint-disable-next-line
  }, [i18next.language, productItem.attributes]);

  const handleImage = (imageSelected) => {
    setimage(imageSelected);
  };
  const handleImage2 = (imageSelected) => {
    setimage2(imageSelected);
  };
  const handleTechnicalSheet = (sheetSelected) => {
    settechnicalSheet(sheetSelected);
  };

  const itemStyle = "card-body noBorder mb-4 p-0 rounded-10";
  const headerStyle = "mb-0 f24 fw-normal text-orange";

  return (
    <Accordion className=" rounded-10 mt-4 ">
      <Accordion.Item className={itemStyle} eventKey="0">
        <Accordion.Header className="d-flex align-items-center">
          <h2 className={headerStyle}>Séction Générale</h2>
        </Accordion.Header>
        <Accordion.Body>
          <InputText labelinput="Nom du produit" handleChange={handleProductChange} champs="name" value={productInputs.name} />
          {/* =================================== URL REWRITE ============================================ */}
          <InputText labelinput="Url" handleChange={handleProductChange} champs="path" value={productUrlRewrite(productItem.id, productInputs.name)} />
          {/* =================================== URL REWRITE ============================================ */}
          <InputText labelinput="Titre Carousel" handleChange={handleProductChange} champs="titre1" value={productInputs.titre1} />
          <InputText labelinput="Sous-Titre Carousel" handleChange={handleProductChange} champs="soustitre1" value={productInputs.soustitre1} />
          <InputText labelinput="2eme Sous-Titre Carousel" handleChange={handleProductChange} champs="soustitre2" value={productInputs.soustitre2} />
          <InputText labelinput="Titre Carte 1 Page Produit" handleChange={handleProductChange} champs="titre2" value={productInputs.titre2} />
          <InputText labelinput="Titre Fiche Technique" handleChange={handleProductChange} champs="titre3" value={productInputs.titre3} />
          <InputText labelinput="Titre Menu" handleChange={handleProductChange} champs="titre4" value={productInputs.titre4} />
          <InputText labelinput="Sous-Titre Menu" handleChange={handleProductChange} champs="soustitre3" value={productInputs.soustitre3} />
          <InputText labelinput="Description" textArea handleChange={handleProductChange} champs="description" value={productInputs.description} required />
          <InputText labelinput="Prix €" handleChange={handleProductChange} champs="price" value={productInputs.price} required />
          <div className="d-flex mt-4">
            <p className="me-2">Afficher ce Produit Sur la carousel principale ?</p>
            <Form.Check className="me-2" type="switch" defaultChecked={productInputs.showCarousel} onChange={(e) => setproductInputs({ ...productInputs, showCarousel: e.target.checked })} />
          </div>{" "}
          {productInputs.showCarousel ? (
            <div className="w-40">
              <InputText labelinput="Ordre d'affichage dans la carousel" type="number" handleChange={handleProductChange} champs="order" value={productInputs.order} />
            </div>
          ) : null}
          {/* <Switch label="Afficher ce Produit Sur la carousel principale ?" values={[]} handleChange={handleProductChange} champs="showCarousel" value={productInputs.showCarousel} /> */}
          <Switch label="type" values={types} handleChange={handleProductChange} champs="type" value={productInputs.type} required />
          {productInputs.type === 0 ? <Switch label="collection" values={collections} handleChange={handleProductChange} champs="collection" value={productInputs.collection} required /> : null}
          {productInputs.type === 1 ? (
            <Switch label="collection" values={spaDeNageCollections} handleChange={handleProductChange} champs="collection" value={productInputs.collection} required />
          ) : null}
          {productInputs.type === 2 ? <Switch label="collection" values={saunaCollections} handleChange={handleProductChange} champs="collection" value={productInputs.collection} required /> : null}
          <Switch label="nombre de places" values={placesNumber} handleChange={handleProductChange} champs="places" value={productInputs.places} required />
          <Switch label="En Stock" values={availability} handleChange={handleProductChange} champs="onstock" value={productInputs.onstock} required />
          <ProductSubmitButton handleUpdateProduct={handleUpdateProduct} productInputs={productInputs} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className={itemStyle} eventKey="1">
        <Accordion.Header>
          <h2 className={headerStyle}>Fiche Téchnique</h2>
        </Accordion.Header>
        <Accordion.Body>
          <div className="d-flex justify-content-center">
            <div>
              <Uploader handleImage={handleTechnicalSheet} image={technicalSheet} resolution={"Pdf"} />
            </div>
          </div>

          <ProductSubmitButton handleUpdateProduct={handleUpdateProduct} productInputs={productInputs} />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item className={itemStyle} eventKey="12">
        <Accordion.Header>
          <h2 className={headerStyle}>Séction Images Principales</h2>
        </Accordion.Header>
        <Accordion.Body>
          <div className="row align-items-center">
            <div className="col">
              <div className="row">
                {image && (
                  <div className="col-6">
                    <Uploader handleImage={handleImage} image={image} resolution={"Image Produit 2D"} />
                  </div>
                )}
                {image2 && (
                  <div className="col-6">
                    <Uploader handleImage={handleImage2} image={image2} resolution={"Image Produit 3D"} />
                  </div>
                )}
              </div>
            </div>
          </div>

          <ProductSubmitButton handleUpdateProduct={handleUpdateProduct} productInputs={productInputs} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className={itemStyle} eventKey="10">
        <Accordion.Header>
          <h2 className={headerStyle}>Section autre images du produit</h2>
        </Accordion.Header>
        <Accordion.Body>{productItem.attributes.versionImages.data && <Lists listData={productItem.attributes.versionImages.data} fetchProduct={productItem.id} resolution="carré" />}</Accordion.Body>
      </Accordion.Item>

      <Accordion.Item className={itemStyle} eventKey="15">
        <Accordion.Header>
          <h2 className={headerStyle}>Section Histoire de thérapie</h2>
        </Accordion.Header>
        <Accordion.Body>{productItem.attributes.jetList.data && <Lists listData={productItem.attributes.jetList.data} fetchProduct={productItem.id} resolution="carré" />}</Accordion.Body>
      </Accordion.Item>

      {productInputs.type === 2 ? null : (
        <Accordion.Item className={itemStyle} eventKey="2">
          <Accordion.Header>
            <h2 className={headerStyle}>Séction Couleurs (Cuves)</h2>
          </Accordion.Header>
          <Accordion.Body>
            {productItem.attributes.cuveColors.data && <Lists listData={productItem.attributes.cuveColors.data} fetchProduct={productItem.id} resolution="carré (couleur)" />}
          </Accordion.Body>
        </Accordion.Item>
      )}
      {productInputs.type === 2 ? null : (
        <Accordion.Item className={itemStyle} eventKey="3">
          <Accordion.Header>
            <h2 className={headerStyle}>Séction Couleurs (Habillage)</h2>
          </Accordion.Header>

          <Accordion.Body>{productItem.attributes.colors.data && <Lists listData={productItem.attributes.colors.data} fetchProduct={productItem.id} resolution="carré (couleur)" />}</Accordion.Body>
        </Accordion.Item>
      )}
      <Accordion.Item className={itemStyle} eventKey="4">
        <Accordion.Header>
          <h2 className={headerStyle}>Séction Réalisations</h2>
        </Accordion.Header>
        <Accordion.Body>
          {productItem.attributes.realisation.data && <Lists listData={productItem.attributes.realisation.data} fetchProduct={productItem.id} resolution="carré (icone)" />}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className={itemStyle} eventKey="5">
        <Accordion.Header>
          <h2 className={headerStyle}>Séction Points Forts</h2>
        </Accordion.Header>
        <Accordion.Body>{productItem.attributes.pointfort.data && <Lists listData={productItem.attributes.pointfort.data} fetchProduct={productItem.id} resolution="carré (icone)" />}</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className={itemStyle} eventKey="6">
        <Accordion.Header>
          <h2 className={headerStyle}>Séction Points de réassurances</h2>
        </Accordion.Header>
        <Accordion.Body>
          {productItem.attributes.pointreassurance.data && <Lists listData={productItem.attributes.pointreassurance.data} fetchProduct={productItem.id} resolution="carré (icone)" />}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item className={itemStyle} eventKey="7">
        <Accordion.Header>
          <h2 className={headerStyle}>Séction Caractèristiques</h2>
        </Accordion.Header>
        <Accordion.Body>
          {productInputs.type === 2 ? (
            <div className="row">
              {saunaCaracteristiqueInputs.map((element, index) => (
                <div className="col-md-4 col-6" key={index}>
                  <InputText
                    labelinput={element.label}
                    handleChange={handleProductChange}
                    champs={`caracrteristique_${element.champs}`}
                    value={productInputs[`caracrteristique_${element.champs}`]}
                    required
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="row">
              {caracteristiqueInputs.map((element, index) => (
                <div className="col-md-4 col-6" key={index}>
                  <InputText
                    labelinput={element.label}
                    handleChange={handleProductChange}
                    champs={`caracrteristique_${element.champs}`}
                    value={productInputs[`caracrteristique_${element.champs}`]}
                    required
                  />
                </div>
              ))}
            </div>
          )}
          {productInputs.type === 2 && productInputs.collection === 1 ? (
            <div className="row">
              {saunaInfrarouge.map((element, index) => (
                <div className="col-md-4 col-6" key={index}>
                  <InputText labelinput={element.label} handleChange={handleProductChange} champs={`equipement_${element.champs}`} value={productInputs[`equipement_${element.champs}`]} required />
                </div>
              ))}
            </div>
          ) : null}{" "}
          {productInputs.type === 1 ? (
            <div className="row">
              {spaDeNageInputs.map((element, index) => (
                <div className="col-md-4 col-6" key={index}>
                  <InputText
                    labelinput={element.label}
                    handleChange={handleProductChange}
                    champs={`caracrteristique_${element.champs}`}
                    value={productInputs[`caracrteristique_${element.champs}`]}
                    required
                  />
                </div>
              ))}
            </div>
          ) : null}
          <ProductSubmitButton handleUpdateProduct={handleUpdateProduct} productInputs={productInputs} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className={itemStyle} eventKey="8">
        <Accordion.Header>
          <h2 className={headerStyle}>Séction Equipements</h2>
        </Accordion.Header>
        <Accordion.Body>
          {productInputs.type === 2 ? (
            <>
              <div className="row">
                {saunaEquipmentsInputs.map((element, index) => (
                  <div className="col-md-4 col-6" key={index}>
                    <InputText labelinput={element.label} handleChange={handleProductChange} champs={`equipement_${element.champs}`} value={productInputs[`equipement_${element.champs}`]} required />
                  </div>
                ))}
              </div>
              <div className="text-start mt-3">* text affiché tel qu'il est</div>
            </>
          ) : (
            <div className="row">
              {equipmentsInputs.map((element, index) => (
                <div className="col-md-4 col-6" key={index}>
                  <InputText labelinput={element.label} handleChange={handleProductChange} champs={`equipement_${element.champs}`} value={productInputs[`equipement_${element.champs}`]} required />
                </div>
              ))}
            </div>
          )}
          {productInputs.type !== 2
            ? equipementSwitches.map((element, index) => (
                <Switch
                  key={index}
                  label={element.label}
                  values={yesOrNo}
                  handleChange={handleProductChange}
                  champs={`equipement_${element.champs}`}
                  value={productInputs[`equipement_${element.champs}`]}
                  required={productInputs[`equipement_${element.champs}`] !== 0}
                  canBeDisabled
                />
              ))
            : null}{" "}
          <ProductSubmitButton handleUpdateProduct={handleUpdateProduct} productInputs={productInputs} />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item className={itemStyle} eventKey="9">
        <Accordion.Header>
          <h2 className={headerStyle}>Séction {productInputs.type !== 2 ? "Jets" : "Accéssoires"}</h2>
        </Accordion.Header>
        <Accordion.Body>
          {productInputs.type !== 2 ? (
            <div className="row">
              {jetInputs.map((element, index) => (
                <div className="col-md-4 col-6" key={index}>
                  <InputText labelinput={element.label} handleChange={handleProductChange} champs={`jets_${element.champs}`} value={productInputs[`jets_${element.champs}`]} required />
                </div>
              ))}
            </div>
          ) : null}
          {productInputs.type !== 2
            ? jetSwitches.map((element, index) => (
                <Switch
                  key={index}
                  label={element.label}
                  values={yesOrNo}
                  handleChange={handleProductChange}
                  champs={`jets_${element.champs}`}
                  value={productInputs[`jets_${element.champs}`]}
                  required={productInputs[`jets_${element.champs}`] !== 0}
                  canBeDisabled
                />
              ))
            : saunaAccessorySwitches.map((element, index) => (
                <Switch
                  key={index}
                  label={element.label}
                  values={yesOrNo}
                  handleChange={handleProductChange}
                  champs={`sauna_acces_${element.champs}`}
                  value={productInputs[`sauna_acces_${element.champs}`]}
                  required={productInputs[`sauna_acces_${element.champs}`] !== 0}
                  canBeDisabled
                />
              ))}{" "}
          <ProductSubmitButton handleUpdateProduct={handleUpdateProduct} productInputs={productInputs} />
        </Accordion.Body>
      </Accordion.Item>
      <RelatedProducts productItem={productItem} relatedProducts={relatedProducts} setrelatedProducts={setrelatedProducts}>
        <ProductSubmitButton handleUpdateProduct={handleUpdateProduct} productInputs={productInputs} />
      </RelatedProducts>
    </Accordion>
  );
};

export default ProductForm;
