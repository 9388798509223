export const types = [
  { value: 0, text: "Spa" },
  { value: 1, text: "Spa De Nage" },
  { value: 2, text: "Sauna" },
];
export const collections = [
  { value: 0, text: "DIVINE LINE" },
  { value: 1, text: "SUMBLIME LINE" },
  { value: 2, text: "CLASSIC LINE" },
  { value: 3, text: "PREMIUM LINE" },
];
export const saunaCollections = [
  { value: 0, text: "SAUNA Finlandais" },
  { value: 1, text: "SAUNA Infrarouge" },
];
export const spaDeNageCollections = [
  { value: 0, text: "Nage River Jet" },
  { value: 1, text: "Nage Turbine" },
];
export const placesNumber = [
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
];
export const availability = [
  { value: 2, text: "En Stock" },
  { value: 1, text: "Sur Demande" },
  { value: 0, text: "Rupture De Stock" },
];
export const yesOrNo = [
  { value: 2, text: "Oui" },
  { value: 1, text: "Non" },
];
export const caracteristiqueInputs = [
  {
    label: "Longueur (cm)",
    champs: "longueur",
  },

  {
    label: "Largeur (cm)",
    champs: "largeur",
  },

  {
    label: "Hauteur (cm)",
    champs: "hauteur",
  },

  {
    label: "Capacités (personnes)",
    champs: "capacite",
  },

  {
    label: "Places allongés",
    champs: "places_allonges",
  },

  {
    label: "Places assise",
    champs: "places_assise",
  },

  {
    label: "Poids (KG)",
    champs: "poids",
  },

  {
    label: "Volume (L)",
    champs: "volume",
  },

  {
    label: "Jets Hydrothérapie",
    champs: "jets_hydrotherapie",
  },

  {
    label: "Jets Aérothérapie",
    champs: "jets_aerotherapie",
  },

  {
    label: "Pompe à eau",
    champs: "pompe_a_eau",
  },

  {
    label: "Pompe à air (W)",
    champs: "pompe_a_air",
  },

  {
    label: "Pompe de filtration (HP)",
    champs: "pompe_de_filtration",
  },
  {
    label: "Ordinateur de bord",
    champs: "ordinateur_de_bord",
  },
  {
    label: "Boitier Eléctrique Balboa",
    champs: "boitier_electrique_balboa",
  },
  {
    label: "Chauffage Balboa (KW)",
    champs: "chauffage_alboa",
  },
  {
    label: "Alimentation électrique",
    champs: "alimentation_electrique",
  },
];

export const spaDeNageInputs = [
  {
    label: "Pompe de nage",
    champs: "pompe_de_nage",
  },
  {
    label: "Jets de nage",
    champs: "jet_de_nage",
  },
];

export const saunaInfrarouge = [
  {
    label: "Puissance totale",
    champs: "puissance_totale",
  },

  {
    label: "Panneaux carbone",
    champs: "panneaux_carbone",
  },

  {
    label: "Panneaux IR à spectre complet",
    champs: "panneau_ir",
  },
];

export const saunaCaracteristiqueInputs = [
  {
    label: "Longueur (cm)",
    champs: "longueur",
  },

  {
    label: "Largeur (cm)",
    champs: "largeur",
  },

  {
    label: "Hauteur (cm)",
    champs: "hauteur",
  },

  {
    label: "Capacités (personnes)",
    champs: "capacite",
  },

  {
    label: "Puissance du poêle (kW)",
    champs: "puissance_de_poele",
  },

  {
    label: "Essence de bois",
    champs: "essence_de_bois",
  },

  {
    label: "Poids (KG)",
    champs: "poids",
  },

  {
    label: "Bancs",
    champs: "bancs",
  },

  {
    label: "Alimentation",
    champs: "alimentation",
  },

  {
    label: "Vitre",
    champs: "vitre",
  },

  {
    label: "Parois",
    champs: "parois",
  },

  {
    label: "Garantie",
    champs: "garantie",
  },
];

export const saunaEquipmentsInputs = [
  {
    label: "Radio *",
    champs: "radio",
  },
  {
    label: "Bluetooth *",
    champs: "blutooth",
  },
  {
    label: "MP3 *",
    champs: "mp3",
  },
  {
    label: "USB *",
    champs: "usb",
  },
  {
    label: "Haut Parleurs *",
    champs: "haut_parleurs",
  },
  {
    label: "Spot lumineux *",
    champs: "spot_lumineux",
  },
  {
    label: "Dossier rétroéclairé *",
    champs: "dossier_retroeclaire",
  },
  {
    label: "couleurs à intensité modulable *",
    champs: "couleurs_a_intensite_modulable",
  },
  {
    label: "Aération",
    champs: "aeration",
  },
  {
    label: "Tablette tactile multifonction",
    champs: "tablette_tactile",
  },
];

export const saunaAccessorySwitches = [
  {
    label: "Seau",
    champs: "seau",
  },
  {
    label: "Louche en bois",
    champs: "louche",
  },
  {
    label: "Thermomètre",
    champs: "thermometre",
  },
  {
    label: "Hygromètre",
    champs: "Hygrometre",
  },
  {
    label: "Sablier traditionnel",
    champs: "sablier_traditionnel",
  },
];

export const equipmentsInputs = [
  {
    label: "Désinfection",
    champs: "desinfection",
  },
  {
    label: "Isolation",
    champs: "isolation",
  },
  {
    label: "Système Audio",
    champs: "audio",
  },
  {
    label: "Haut-Parleur",
    champs: "haut_parleur",
  },
  {
    label: "Wifi",
    champs: "wifi",
  },
  {
    label: "Capot Luxe filtre",
    champs: "capot_luxe_filtre",
  },
  {
    label: "Niveaux de cale pieds",
    champs: "niveaux_de_cale_pieds",
  },
  {
    label: "Régulateur de puissance",
    champs: "regulateur_de_puissance",
  },
  {
    label: "Repose verre",
    champs: "repose_verre",
  },
];

export const equipementSwitches = [
  {
    label: "Acrylics ARISTECH",
    champs: "acrylics_artistech",
  },
  {
    label: "Système BioLok",
    champs: "biolok",
  },
  {
    label: "Filtration à cartouche",
    champs: "filtration_cartouche",
  },
  {
    label: "Filtration basse consommation",
    champs: "filtration_bas_cons",
  },
  {
    label: "Base ABS",
    champs: "base_abs",
  },
  {
    label: "Aromathérapie",
    champs: "aromatherapie",
  },
  {
    label: "Cascade",
    champs: "cascade",
  },
  {
    label: "Easy Drain",
    champs: "easy_drain",
  },
  {
    label: "Bâche hivernation",
    champs: "bach_hivernation",
  },
];

export const jetInputs = [
  {
    label: "Spot de chromothérapie",
    champs: "spot_de_chromotherapie",
  },
  {
    label: "LEDs ligne d'eau",
    champs: "leds",
  },
  {
    label: "Jets Rétro-éclairés",
    champs: "jets_retro_eclaires",
  },
  {
    label: "Cascade rétro-éclairée",
    champs: "cascade_retro_eclairee",
  },
  {
    label: "Rivière rétro-éclairée",
    champs: "riviere_retro_eclairee",
  },
  {
    label: "Appui-tête rétro-éclairé",
    champs: "appui_tete_retro_eclaire",
  },
];

export const jetSwitches = [
  {
    label: "Angles rétro-éclairés",
    champs: "angles_retro_eclaires",
  },
  {
    label: "Panneaux rétro-éclairés",
    champs: "panneaux_retro_eclaires",
  },
  {
    label: "Skimmer rétro-éclairé",
    champs: "skimmer_retro_eclaires",
  },
  {
    label: "ON/OFF rétroéclairés",
    champs: "on_off_retroeclaires",
  },
  {
    label: "Régulateurs rétroéclairés",
    champs: "regulateurs_retroeclaires",
  },
];
