import i18next from "i18next";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEvent, getAllEvents } from "../../REDUX/actions/events";
import CustomButton from "../Common/CustomButton";
import EmptyList from "../Common/EmptyList";
import Loading from "../Common/Loading";
import InputText from "../sectioninput/formelement/InputText";
import EventCard from "./EventCard";

const EventsList = () => {
  const dispatch = useDispatch();
  const [filter, setfilter] = useState("");

  const { events, eventsLoading } = useSelector((state) => state.eventsReducer);
  const filterEvent = () => {
    dispatch(getAllEvents(filter));
  };

  const createEvent = () => dispatch(addEvent());

  return (
    <div className="bg-white rounded-10 container-fluid p-5 shadowed">
      <div className="row f24 align-items-center">Liste des événements</div>
      <div className="row align-items-center mt-2">
        <div className="col-9 d-flex align-items-center">
          <InputText flag={false} placeholder="rechercher un événement" handleChange={setfilter} />
          <div className="ms-3 col-1">
            <CustomButton iconStart={"search"} callBack={() => filterEvent(filter)} />
          </div>
        </div>
        <div className="col-3">
          <CustomButton
            buttonText={i18next.language === "fr" ? "Ajouter un événement" : "Passer en Langue Principale"}
            disabled={i18next.language !== "fr"}
            iconStart={"plus-lg"}
            callBack={createEvent}
          />
        </div>
      </div>
      <div className="row mb-4 w-98 m-auto mt-5">
        <div className="col-4 f16 bolder text-start">Titre</div>
        <div className="col-2 f16 bolder text-start">Publié</div>
        <div className="col-6 f16 bolder text-start">Actions</div>
      </div>
      {eventsLoading ? (
        <Loading />
      ) : (
        <div className="mt-5">{events.length ? events.map((event, index) => <EventCard key={index} event={event} index={index} />) : <EmptyList text="Ajouter votre premier événement" />}</div>
      )}{" "}
    </div>
  );
};

export default EventsList;
