import axios from "axios";
import { END_POINT } from "./EndPoints.js";
const API = axios.create({
  baseURL: END_POINT,
});

// API.defaults.headers = {

// };

export default API;
