import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeProductsShowed } from "../../REDUX/actions/revendeur";
import CustomButton from "../Common/CustomButton";
import Loading from "../Common/Loading";
import LoadingButton from "../Common/LoadingButton";

const ShowRoomExposedProducts = ({ showRoomItem }) => {
  const dispatch = useDispatch();
  const { productsLoading, products } = useSelector((state) => state.productsReducer);
  const { showRoomsLoading } = useSelector((state) => state.showRoomsReducer);

  const [productsExposed, setproductsExposed] = useState();

  useEffect(() => {
    if (showRoomItem) {
      setproductsExposed(showRoomItem.attributes.products.data.map((el) => el.id));
    }
  }, [showRoomItem]);

  const handleExposedModels = () => {
    dispatch(changeProductsShowed(showRoomItem.id, productsExposed));
  };
  return !products || !productsExposed || productsLoading ? (
    <Loading />
  ) : (
    <Accordion className={`text-start rounded-10 `}>
      <Accordion.Item className="card-body noBorder p-0 rounded-10" eventKey="0">
        <Accordion.Header className="rounded-10">
          <h2 className="mb-0 f24 fw-normal">Les modèles exposés</h2>
        </Accordion.Header>
        <Accordion.Body>
          <form className="d-flex flex-wrap justify-content-center">
            {products.map((el, index) => (
              <div className="form-check form-switch my-3 w-30" key={index}>
                <input
                  defaultChecked={productsExposed.includes(el.id)}
                  className="form-check-input"
                  value={el.id}
                  type="checkbox"
                  id={el.id}
                  onChange={(e) => {
                    if (e.target.checked) {
                      productsExposed.push(parseInt(e.target.value));
                    } else {
                      const i = productsExposed.indexOf(parseInt(e.target.value));
                      if (i > -1) {
                        productsExposed.splice(i, 1);
                      }
                    }
                  }}
                />
                <label className="form-check-label" htmlFor={el.id}>
                  {el.attributes.name}
                </label>
              </div>
            ))}
          </form>
          <div className="my-3">
            <CustomButton disabled={showRoomsLoading} buttonText={showRoomsLoading ? <LoadingButton /> : "Enregistrer les produits exposés"} callBack={handleExposedModels} />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ShowRoomExposedProducts;
