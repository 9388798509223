import React, { useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { publishOrNoPublishArticle, updateArticle } from "../../REDUX/actions/articles";
import { openModal } from "../../REDUX/actions/confirmationModal";
import InputText from "../sectioninput/formelement/InputText";
import { publishOrNoPublishEvent, updateEvent } from "../../REDUX/actions/events";
import CustomButton from "../Common/CustomButton";
import Uploader from "../Common/Uploader";
import _ from "lodash";
import moment from "moment";

const SectionTopPanel = ({ eventItemForm, title = "Modifier l'article", type = "article" }) => {
  const dispatch = useDispatch();
  const [articleInputs, setarticleInputs] = useState(
    _.omit(eventItemForm.attributes, ["locale", "image", "createdAt", "updatedAt", "localizations", "banner", "sections", "authorImage", "seo", "revendeurs"])
  );

  useEffect(() => {
    setarticleInputs(_.omit(eventItemForm.attributes, ["locale", "image", "createdAt", "updatedAt", "localizations", "banner", "sections", "authorImage", "seo", "revendeurs"]));
  }, [eventItemForm]);

  const [authorImage, setauthorImage] = useState(eventItemForm.attributes.authorImage?.data);

  const showModalPublish = (checked) => {
    dispatch(
      openModal({
        confirmButtonText: checked ? "Publier" : "Retirer",
        cancelButton: true,
        message: checked
          ? `Êtes-vous sûr(e) de vouloir publier de ${type === "article" ? "l'article" : "l'événement"} ?`
          : `Êtes-vous sûr(e) de vouloir rendre ${type === "article" ? "l'article" : "l'événement"} non publié ?`,
        callBack: () => handlePublish(checked),
      })
    );
  };
  const handleAuthorImage = (newImage) => {
    setauthorImage(newImage);
  };
  const handlePublish = (checked) => dispatch(type === "article" ? publishOrNoPublishArticle(eventItemForm.id, checked) : publishOrNoPublishEvent(eventItemForm.id, checked));

  const handleChange = (value, champs) => {
    setarticleInputs({ ...articleInputs, [champs]: value });
  };

  const handleUpdateArticle = () => dispatch(updateArticle(eventItemForm.id, { ...articleInputs, authorImage: authorImage.id }));
  const handleUpdateEvent = () => dispatch(updateEvent(eventItemForm.id, articleInputs));

  return (
    <Accordion className={`text-start rounded-10 `} defaultActiveKey={0}>
      <Accordion.Item className="card-body noBorder p-0 rounded-10">
        <Accordion.Header>
          <h2 className="mb-0 f24 fw-normal"> {title}</h2>
        </Accordion.Header>
        <Accordion.Body>
          <InputText labelinput="Titre" value={articleInputs.title} handleChange={handleChange} champs="title" />
          <InputText labelinput="Sous Titre" value={articleInputs.subtitle} handleChange={handleChange} champs="subtitle" />
          <InputText labelinput="Description" textArea value={articleInputs.description} handleChange={handleChange} champs="description" />
          {type === "events" ? <InputText type="datetime-local" labelinput="date" value={moment(articleInputs.date).format("yyyy-MM-DDThh:mm")} handleChange={handleChange} champs="date" /> : null}
          {type === "events" ? (
            <InputText type="datetime-local" labelinput="date de Fin" value={moment(articleInputs.dateEnd).format("yyyy-MM-DDThh:mm")} handleChange={handleChange} champs="dateEnd" />
          ) : null}
          {type === "events" ? (
            <div className="text-start my-2">
              <label className="f16 ">Type de l'événement</label>
              <div className="d-flex align-items-center gap-2 my-1">
                <div className={`flagInput rounded-3 flaginputfr invisible`}></div>
                <Form.Select onChange={(e) => setarticleInputs({ ...articleInputs, type: parseInt(e.target.value) })} defaultValue={articleInputs.type}>
                  <option value={0}>Salon & Foire</option>
                  <option value={1}>Salon Pro</option>
                  <option value={2}>Sport</option>
                </Form.Select>
              </div>
            </div>
          ) : null}
          {type === "events" ? <InputText labelinput="Adresse" value={articleInputs.place} handleChange={handleChange} champs="place" /> : null}
          {type !== "events" ? <InputText labelinput="Auteur" value={articleInputs.author} handleChange={handleChange} champs="author" /> : null}
          {type !== "events" ? <InputText labelinput="Titre de l'auteur" value={articleInputs.authorTitle} handleChange={handleChange} champs="authorTitle" /> : null}
          {type !== "events" ? <Uploader labelinput="Image de l'auteur" resolution="carré" image={authorImage} handleImage={handleAuthorImage} /> : null}
          {type !== "events" ? (
            <div className="text-start my-2">
              <label className="f16 ">Type de l'article</label>
              <div className="d-flex align-items-center gap-2 my-1">
                <div className={`flagInput rounded-3 flaginputfr invisible`}></div>
                <Form.Select onChange={(e) => setarticleInputs({ ...articleInputs, type: parseInt(e.target.value) })} defaultValue={articleInputs.type}>
                  <option value={0}>Conseil & Entretien</option>
                  <option value={1}>Article Presse</option>
                </Form.Select>
              </div>
            </div>
          ) : null}

          <div className="d-flex mt-4">
            <p className="me-2">Publié</p>
            <Form.Check
              className="me-2"
              type="switch"
              checked={articleInputs.publish}
              onChange={(e) => {
                e.preventDefault();
                showModalPublish(e.target.checked);
              }}
            />
          </div>

          <div className="mt-4">
            <CustomButton iconStart="check2" buttonText="Enregistrer" callBack={type === "article" ? handleUpdateArticle : handleUpdateEvent} />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SectionTopPanel;
