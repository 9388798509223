import "./App.css";
import Layout from "./Components/layout/Layout.jsx";
import Routes from "./Routes/Routes.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter } from "react-router-dom";
import "./custom.scss";
import "@splidejs/react-splide/css/skyblue";

import { useTranslation } from "react-i18next";
import AuthCheck from "./Components/AuthCheck/AuthCheck.jsx";

function App() {
  // eslint-disable-next-line
  const { t } = useTranslation();
  return (
    <div className="App">
      <BrowserRouter>
        <AuthCheck>
          <Layout>
            <Routes />
          </Layout>
        </AuthCheck>
      </BrowserRouter>
    </div>
  );
}

export default App;
