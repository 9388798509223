const initialState = {
  pagesLoading: false,
  pagesData: null,
  landingPages: null,
  landingPagesLoading: false,
  ActivePage: null,
  error: null,
  suppLoading: false,
  seoLoading: false,
  sectionLoading: false,
};

export const pagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PAGES_PAGE":
      return {
        ...state,
        pagesData: action.payload,
        pagesLoading: false,
        suppLoading: false,
        seoLoading: false,
      };
    case "FETCH_LANDING_PAGES":
      return {
        ...state,
        landingPages: action.payload,
        landingPagesLoading: false,
        suppLoading: false,
        seoLoading: false,
      };
    case "FETCH_HOME_PAGE":
      return {
        ...state,
        ActivePage: action.payload,
        pagesLoading: false,
        suppLoading: false,
        seoLoading: false,
      };
    case "PAGES_SUPP_LOADING":
      return { ...state, suppLoading: action.payload };
    case "PAGES_SEO_LOADING":
      return { ...state, seoLoading: action.payload };
    case "PAGES_LOADING":
      return { ...state, pagesLoading: true };
    case "LANDING_PAGES_LOADING":
      return { ...state, landingPagesLoading: true };
    case "SECTION_LOADING":
      return { ...state, sectionLoading: action.payload };
    case "FETCH_ITEM_PAGE":
      return { ...state, pagesLoading: false, ActivePage: action.payload };
    case "INSERT_DATA":
      return { ...state, ActivePage: action.payload, pagesLoading: false };

    default:
      return state;
  }
};
