import i18next from "i18next";
import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import SEOInputs from "../../Components/PageCard/SEOInputs";
import ProductForm from "../../Components/Products/ProductForm";
import SectionInput from "../../Components/sectioninput/SectionInput";
import { getProductItem } from "../../REDUX/actions/products";

const ProductItemView = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (state.enterWithLanguage === i18next.language) {
      dispatch(getProductItem(params.id));
    } else {
      dispatch(getProductItem(state.idTrans));
    } // eslint-disable-next-line
  }, [, i18next.language]);

  const { productItem } = useSelector((state) => state.productsReducer);
  return !productItem && params.id !== "new" ? (
    <Loading />
  ) : (
    <div key={productItem.id}>
      <Accordion className=" rounded-10 ">
        <SEOInputs
          seo={productItem.attributes.seo.data}
          title={productItem.attributes.name}
          collapsed
        />
        <div className="my-4"></div>
        <SectionInput
          sectionData={productItem.attributes.banner.data.attributes}
          withSeo
          sectionId={productItem.attributes.banner.data.id}
          titleBlack="Bannière du produit"
        />
        <div className="my-4"></div>
        {/* <SectionInput
          sectionData={productItem.attributes.jetCard.data.attributes}
          sectionId={productItem.attributes.jetCard.data.id}
          fetchProduct={productItem.id}
          titleBlack="Histoire de thérapie"
          noBtn
        /> */}
      </Accordion>
      <ProductForm productItem={productItem} />
    </div>
  );
};

export default ProductItemView;
