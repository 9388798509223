import i18next from "i18next";
import API from "../../Api/Api.js";
import { otherLanguage } from "../../utils/utils.js";
import { getArticleItem } from "./articles.js";
import { closeModal } from "./confirmationModal.js";
import { getEventItem } from "./events.js";
import { handleError, handleInfo } from "./notifications.js";
import { realTimeInsertData } from "./pages.js";
import qs from "qs";
import { pageQueryAll } from "./Queries.js";

const token = localStorage.getItem("token");

export const updateSection = (section, sectionId) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  dispatch({ type: "SECTION_LOADING", payload: true });
  const query = qs.stringify(
    {
      populate: {
        page: {
          populate: "*",
        },
        seo: {
          populate: "ogimage",
        },
      },
    },
    { encodeValuesOnly: true }
  );
  try {
    const newSection = await API.put(`/api/sections/${sectionId}?${query}`, {
      data: section,
    });
    if (newSection.data.data.attributes.page.data) {
      let newPage = await API.get(
        `api/pages/${newSection.data.data.attributes.page.data?.id}?` +
          pageQueryAll
      );

      dispatch(realTimeInsertData(newPage.data.data));
    }

    dispatch({ type: "SECTION_LOADING", payload: false });
    dispatch(handleInfo("Section mise à jour", 1));
  } catch (error) {
    dispatch(handleError("Erreur lors de la mise à jour de la section", 1));
    console.log(error);
  }
};

export const addSection =
  (
    type,
    parentId,
    parentIdTrans,
    allSectionIds,
    allSectionIdsTrans,
    withImage
  ) =>
  async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;
    API.defaults.params = {};
    let defaultImage = null;

    try {
      let image = await API.get("/api/upload/files");
      defaultImage = image.data[0].id;
    } catch (error) {
      dispatch(handleError("Erreur reseau", 1));

      console.log(error);
    }

    try {
      if (!defaultImage) {
        return dispatch(
          handleError("Importer au moin une image pour procéder", 1)
        );
      }
      const defaultSection = {
        title: "section par default",
        subtitle: "soustitre par default",
        content: "contenu Par défault",
        resolution: "Carré (800 * 800)",
        image: withImage ? [defaultImage] : null,
        imageMobile: withImage ? [defaultImage] : null,
      };
      const defaultSectionEng = {
        title: "default section",
        subtitle: "default subtitle",
        content: "default content",
        resolution: "Carré (800 * 800)",
        image: withImage ? [defaultImage] : null,
        imageMobile: withImage ? [defaultImage] : null,
      };

      const sectionFr = await API.post("/api/sections", {
        data: defaultSection,
        params: { locale: i18next.language },
      });
      const sectionEn = await API.post(
        `/api/sections/${sectionFr.data.data.id}/localizations`,
        { locale: otherLanguage(i18next.language), ...defaultSectionEng }
      );
      const allSectionsPrimary = [...allSectionIds, sectionFr.data.data.id];
      const allSectionsTrans = [...allSectionIdsTrans, sectionEn.data.id];

      await API.put(`/api/${type}/${parentId}`, {
        data: { sections: allSectionsPrimary },
        params: { locale: i18next.language },
      });
      await API.put(`/api/${type}/${parentIdTrans}`, {
        data: { sections: allSectionsTrans },
        params: { locale: i18next.language },
      });

      dispatch(handleInfo("Section Ajouté (en 2 langues)", 1));
      if (type === "articles") {
        dispatch(getArticleItem(parentId));
      } else {
        dispatch(getEventItem(parentId));
      }
    } catch (error) {
      dispatch(handleError("erreur réseau", 1));
      console.log(error);
    }
  };

export const updateSEO = (id, data, pageId) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  dispatch({ type: "PAGES_SEO_LOADING", payload: true });

  try {
    await API.put(`/api/seos/${id}`, { data: data });
    if (pageId) {
      const page = await API.get(`api/pages/${pageId}?${pageQueryAll}`);
      dispatch(realTimeInsertData(page.data.data));
    }
    dispatch({ type: "PAGES_SEO_LOADING", payload: false });
    dispatch(handleInfo("SEO mise à jour", 1));
  } catch (error) {
    dispatch(handleError("Erreur lors de la mise à jour des données SEO", 1));
    console.log(error);
  }
};

export const deleteSection = (id, parentId, type) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  try {
    await API.delete(`/api/sections/${id}`);
    if (type === "articles") {
      dispatch(getArticleItem(parentId));
    } else {
      dispatch(getEventItem(parentId));
    }
    dispatch(closeModal());
    dispatch(handleInfo("Section Supprimé", 1));
  } catch (error) {
    dispatch(handleError("Erreur lors de la mise à jour des données SEO", 1));
    console.log(error);
  }
};
