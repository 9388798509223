import qs from "qs";

export const pageQueryOnlyLocale = qs.stringify(
  {
    filters: { landing: { $eq: false } },
    sort: ["title:asc"],
    populate: {
      localizations: {
        populate: "*",
      },
    },
  },

  {
    encodeValuesOnly: true,
  }
);
export const landingPagesQueries = qs.stringify(
  {
    filters: { landing: { $eq: true } },
    populate: { seo: { fields: "url" } },
    sort: ["id:asc"],
  },

  {
    encodeValuesOnly: true,
  }
);
export const pageQueryAll = qs.stringify(
  {
    sort: ["title:asc"],
    populate: {
      sections: {
        populate: {
          image: {
            populate: "*",
          },
          image2: { populate: ["*"] },
          imageMobile: { populate: ["*"] },
          imageMobile2: { populate: ["*"] },
          list: {
            populate: {
              cards: {
                populate: ["image", "image2", "imageMobile", "imageMobile2"],
              },
            },
          },
        },

        sort: ["order:asc"],
      },

      seo: {
        populate: "ogimage",
      },
    },
  },

  {
    encodeValuesOnly: true,
  }
);
