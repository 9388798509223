import React, { useEffect, useState } from "react";
import PageList from "../../Components/PageCard/PageList";
import { useDispatch, useSelector } from "react-redux";
import {
  getLandingPages,
  handleNewLandingPage,
} from "../../REDUX/actions/pages";
import CustomButton from "../../Components/Common/CustomButton";
import { Accordion } from "react-bootstrap";
import InputText from "../../Components/sectioninput/formelement/InputText";
import Loading from "../../Components/Common/Loading";

const LandingPagesView = () => {
  const dispatch = useDispatch();
  const FRONT_SITE_URL = "https://poseidon-spa.com";
  const { landingPagesLoading, landingPages } = useSelector(
    (state) => state.pagesReducer
  );
  const [formData, setformData] = useState({ title: "", url: "" });
  const handleChange = (value, champs) => {
    setformData({ ...formData, [champs]: value });
  };
  useEffect(() => {
    if (!landingPages) {
      dispatch(getLandingPages());
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <PageList
        pagesLoading={landingPagesLoading}
        pagesData={landingPages}
        FRONT_SITE_URL={FRONT_SITE_URL}
      />
      {landingPagesLoading ? (
        <Loading />
      ) : (
        <div className="w-100 mt-5 d-flex justify-content-center">
          <Accordion
            className=" w-100 my-4 text-start rounded-10 bg-white shadowed"
            defaultActiveKey={"1"}
          >
            <Accordion.Item
              className="card-body accordionCustom rounded-10 p-0"
              eventKey="0"
            >
              <Accordion.Header>
                <div className=" f24">Ajouter une nouvelle page </div>
              </Accordion.Header>
              <Accordion.Body className="d-flex flex-column align-items-center">
                <div className="d-flex gap-4 w-100">
                  <InputText
                    labelinput="Nom de la page"
                    value={formData.title}
                    handleChange={handleChange}
                    champs="title"
                  />
                  <InputText
                    labelinput="URL de la page"
                    value={formData.url}
                    handleChange={handleChange}
                    champs="url"
                  />
                </div>
                <div className="mt-4 w-50">
                  <CustomButton
                    disabled={!formData.title}
                    buttonText={"ajouter une page"}
                    callBack={() => dispatch(handleNewLandingPage(formData))}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* <CustomButton buttonText={"ajouter une page"} /> */}
        </div>
      )}
    </div>
  );
};

export default LandingPagesView;
