import React from "react";
import Drawer from "../Drawer/Drawer";
import Header from "./Header/Header";
import "./Layout.css";
import { useLocation } from "react-router-dom";
import Notification from "./Notification/Notification";
import ConfirmationModal from "./ConfirmationModal";

const Layout = ({ children }) => {
  const location = useLocation();

  const noLayoutRoutes = ["/login"];

  if (location.pathname.includes(noLayoutRoutes)) {
    return (
      <div className="d-flex justify-content-center align-items-center layoutContentLogin">
        {children}
      </div>
    );
  } else {
    return (
      <div className="d-flex">
        <Drawer />
        <div className="layoutRight">
          <Header />
          <div className="container-fluid my-4">
            <ConfirmationModal />
            <Notification
              BgClassnameColor="alert-primary"
              Title="👋 Bonjour Armand, bienvenue sur votre dashboard [“entreprise”] !"
              closeNotification=""
            />
            <div className="layoutContent">{children}</div>
            <div className="mt-2 text-darkGrey">
              Tous droits réservés © Tendances.DEV - Tendances.Group 2022
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Layout;
