import React, { useEffect, useState } from "react";
import "./InputText.css";
import i18n from "i18next";
import JoditEditor from "jodit-react";

const InputText = ({ labelinput, textArea = false, white, flag = true, name, value = "", handleChange, champs, readOnly = false, placeholder, type = "text" }) => {
  const [focused, setFocused] = useState("");
  const [, setmyValue] = useState(value);
  useEffect(() => {
    setmyValue(value);
    // eslint-disable-next-line
  }, [i18n.language, value]);

  return (
    <div className="text-start pt-2 pb-2 w-100">
      {labelinput && <label className="f16">{labelinput}</label>}
      <div className="d-flex align-items-center gap-2">
        {flag && <div className={`flagInput rounded-3 flaginput${i18n.language} ${focused}`}></div>}

        {value ? (
          textArea ? (
            <JoditEditor readOnly={readOnly} value={value} onChange={(e) => handleChange(e, champs)} />
          ) : (
            <input
              value={value}
              readOnly={readOnly}
              placeholder={placeholder}
              type={type}
              onChange={(e) => handleChange(e.target.value, champs)}
              name={name}
              className={`styleInput w-100 ps-3 rounded-3 ${white ? "bg-white" : ""}`}
              onFocus={() => setFocused("flagFocused")}
              onBlur={() => setFocused("")}
            />
          )
        ) : textArea ? (
          <JoditEditor readOnly={readOnly} value={value} onChange={(e) => handleChange(e, champs)} />
        ) : (
          <input
            readOnly={readOnly}
            type={type}
            onChange={(e) => handleChange(e.target.value, champs)}
            placeholder={placeholder}
            name={name}
            className={`styleInput w-100 ps-3 rounded-3 ${white ? "bg-white" : ""}`}
            onFocus={() => setFocused("flagFocused")}
            onBlur={() => setFocused("")}
          />
        )}
      </div>
    </div>
  );
};

export default InputText;
