import i18next from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArticlesList from "../../Components/Articles/ArticlesList";
import Loading from "../../Components/Common/Loading";
import PageCard from "../../Components/PageCard/PageCard";
import { getAllArticles } from "../../REDUX/actions/articles";
import { getPageByName } from "../../REDUX/actions/pages";

const BlogView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPageByName("Page Blog"));
    dispatch(getAllArticles()); // eslint-disable-next-line
  }, [i18next.language]);

  const { pagesLoading, ActivePage } = useSelector((state) => state.pagesReducer);
  const { articles } = useSelector((state) => state.articlesReducer);

  return pagesLoading || !ActivePage || !articles ? (
    <Loading />
  ) : (
    <>
      <PageCard page={ActivePage} collapsed />
      <ArticlesList />
    </>
  );
};

export default BlogView;
