import i18next from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import CustomButton from "../../Components/Common/CustomButton";
import Loading from "../../Components/Common/Loading";
import PageCard from "../../Components/PageCard/PageCard";
import { getPageItem } from "../../REDUX/actions/pages";
import { pageLink } from "../../utils/utils";
import "./PageItemView.css";

const PageItemView = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (state.enterWithLanguage === i18next.language) {
      dispatch(getPageItem(params.id));
    } else {
      dispatch(getPageItem(state.idTrans));
    } // eslint-disable-next-line
  }, [i18next.language]);

  const { pagesLoading, ActivePage } = useSelector(
    (state) => state.pagesReducer
  );
  const FRONT_SITE_URL = "https://poseidon-spa.com";

  const checkPage = (goTo) => {
    window.open(FRONT_SITE_URL + goTo);
  };
  const viewLanding = (goTo) => {
    window.open(goTo);
  };

  return pagesLoading || !ActivePage ? (
    <Loading />
  ) : (
    <div key={ActivePage.id}>
      <div className="row justify-content-end consultButtonContainer">
        <div className="col-2 consultButton ">
          <CustomButton
            buttonText={"Consulter"}
            type="primary"
            iconStart="eye-fill"
            callBack={() =>
              !ActivePage.attributes.landing
                ? checkPage(pageLink(ActivePage.attributes.title))
                : viewLanding(ActivePage.attributes.seo.data.attributes.url)
            }
          />
        </div>
      </div>
      <PageCard page={ActivePage} collapsed={true} />
    </div>
  );
};

export default PageItemView;
