import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../Common/CustomButton";
import { closeModal } from "../../REDUX/actions/confirmationModal";
import "./ConfirmationModal.css";

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const { open, message, cancelButton, confirmButtonText, type, callBack, cancelCallback, component } = useSelector((state) => state.modalReducer);

  const handleClose = () => {
    dispatch(closeModal());

    // if (cancelCallback) {
    //   cancelCallback();
    // }
  };

  return (
    <Modal show={open} onHide={handleClose} size={"lg"}>
      <Modal.Header>
        <div>Confirmation</div>
      </Modal.Header>
      <Modal.Body>
        {message ? <div className="my-4 messageStyle" dangerouslySetInnerHTML={{ __html: message }}></div> : null}
        {component}
      </Modal.Body>
      <Modal.Footer>
        <div className="row container-fluid justify-content-around">
          <div className="col-6 ">{cancelButton && <CustomButton outlined buttonText={"Annuler"} iconStart="x-lg" callBack={handleClose} />} </div>
          <div className="col-6">
            <CustomButton
              buttonText={confirmButtonText}
              type={type === "delete" ? "red" : "primary"}
              iconStart={type === "delete" ? "trash" : type === "goback" ? "caret-left-fill" : "check-lg"}
              callBack={callBack}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
