import i18next from "i18next";
import API from "../../Api/Api.js";
import { otherLanguage } from "../../utils/utils.js";
import { closeModal } from "./confirmationModal.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
const token = localStorage.getItem("token");

export const getAllEvents = (filter) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  const query = qs.stringify(
    {
      filters: {
        title: {
          $containsi: filter,
        },
      },
      populate: {
        localizations: {
          populate: "*",
        },
        banner: {
          populate: "*",
        },
      },
      sort: ["createdAt:desc"],
    },

    {
      encodeValuesOnly: true,
    }
  );
  API.defaults.params = { locale: i18next.language };
  dispatch({ type: "EVENT_LOADING" });
  try {
    let { data } = await API.get("/api/events?" + query);
    dispatch({ type: "FETCH_EVENTS", payload: data.data });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  API.defaults.params = {};
  dispatch({ type: "EVENT_LOADING" });
  try {
    await API.delete(`api/events/${id}`);
    dispatch(getAllEvents());
    dispatch(closeModal());
  } catch (error) {
    dispatch(handleError("Erreur lors de la suppression de l'événement", 1));
    console.log(error);
  }
};

export const publishOrNoPublishEvent = (id, published) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "EVENT_LOADING" });
  try {
    await API.put(`api/events/${id}`, { data: { publish: published } });
    dispatch(getEventItem(id));
    dispatch(getAllEvents());
    dispatch(closeModal());
    dispatch(
      handleInfo(published ? "événement publié" : "événement retiré", 1)
    );
  } catch (error) {
    dispatch(handleError("Erreur lors de la suppression  de l'événement", 1));
    console.log(error);
  }
};

export const addEvent = () => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  API.defaults.params = {};
  let defaultImage = null;

  dispatch({ type: "EVENT_LOADING" });
  try {
    let image = await API.get("/api/upload/files");
    defaultImage = image.data[0].id;
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }

  try {
    if (!defaultImage) {
      return dispatch(
        handleError("Importer au moin une image pour procéder", 1)
      );
    }
    const defaultBanner = {
      title: "banniere par default",
      subtitle: "soustitre par default",
      image: [defaultImage],
      imageMobile: [defaultImage],
    };
    const defaultBannerEng = {
      title: "banniere par default (en)",
      subtitle: "soustitre par default(en)",
      image: [defaultImage],
      imageMobile: [defaultImage],
    };

    let defaultArticleFR = {
      title: "titre événement par défault",
      subtitle: "sous-titre événement par défault",
      writer: "auteur par défault",
      writerImage: [defaultImage],
      description: "description Par défault",
    };
    let defaultArticleEN = {
      title: "titre événement par défault (en)",
      subtitle: "sous-titre événement par défault (en)",
      writer: "auteur par défault",
      writerImage: [defaultImage],
      description: "description Par défault (en)",
    };

    let defaultSEOFR = {
      title: "titre SEO article par defaut",
      description: "SEO description article par defaut",
      ogimage: defaultImage,
    };
    let defaultSEOEN = {
      title: "titre SEO article par defaut (en)",
      description: "SEO description article par defaut (en)",
      ogimage: defaultImage,
    };

    let SEOfr = await API.post("/api/seos", {
      data: defaultSEOFR,
      params: { locale: i18next.language },
    });
    const SEOen = await API.post(
      `/api/seos/${SEOfr.data.data.id}/localizations`,
      { locale: otherLanguage(i18next.language), ...defaultSEOEN }
    );

    let bannerfr = await API.post("/api/sections", {
      data: defaultBanner,
      params: { locale: i18next.language },
    });
    const banneren = await API.post(
      `/api/sections/${bannerfr.data.data.id}/localizations`,
      { locale: otherLanguage(i18next.language), ...defaultBannerEng }
    );

    const eventFr = await API.post("/api/events", {
      data: {
        banner: bannerfr.data.data.id,
        seo: SEOfr.data.data.id,
        ...defaultArticleFR,
      },
      params: { locale: i18next.language },
    });

    await API.post(`/api/events/${eventFr.data.data.id}/localizations`, {
      locale: otherLanguage(i18next.language),
      banner: banneren.data.id,
      ...defaultArticleEN,
      seo: SEOen.data.id,
    });

    dispatch(handleInfo("événement Créé", 1));
    dispatch(getAllEvents());
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const getEventItem = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  const query = qs.stringify(
    {
      filters: { id: { $eq: id } },
      populate: {
        localizations: {
          populate: "*",
        },
        banner: {
          populate: "*",
        },
        sections: {
          populate: "*",
        },
        revendeurs: {
          populate: "*",
        },
        seo: {
          populate: "ogimage",
        },
      },
    },
    { encodeValuesOnly: true }
  );
  API.defaults.params = { locale: i18next.language };
  dispatch({ type: "EVENT_LOADING" });
  try {
    let { data } = await API.get("/api/events?" + query);

    dispatch({ type: "FETCH_EVENT_ITEM", payload: data.data[0] });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const updateEvent = (id, event) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  dispatch({ type: "EVENT_LOADING" });
  try {
    await API.put(`/api/events/${id}`, { data: event });

    dispatch(handleInfo("événement Modifié", 1));
    dispatch(getEventItem(id));
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const changeassociatedShowrooms =
  (id, associatedShowrooms) => async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;

    dispatch({ type: "EVENT_LOADING" });
    try {
      await API.put(`/api/events/${id}`, {
        data: { revendeurs: associatedShowrooms },
      });

      dispatch(handleInfo("Evénement Modifié", 1));
      dispatch(getEventItem(id));
    } catch (error) {
      dispatch(handleError("erreur réseau", 1));
      console.log(error);
    }
  };
