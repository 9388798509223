import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeAssociatedEvents } from "../../REDUX/actions/revendeur";
import CustomButton from "../Common/CustomButton";
import Loading from "../Common/Loading";
import LoadingButton from "../Common/LoadingButton";

const ShowRoomAssociatedEvents = ({ showRoomItem }) => {
  const dispatch = useDispatch();
  const { events, eventsLoading } = useSelector((state) => state.eventsReducer);
  const { showRoomsLoading } = useSelector((state) => state.showRoomsReducer);

  const [associatedEvents, setassociatedEvents] = useState();

  useEffect(() => {
    if (showRoomItem) {
      setassociatedEvents(showRoomItem.attributes.events.data.map((el) => el.id));
    }
  }, [showRoomItem]);

  const handleExposedModels = () => {
    dispatch(changeAssociatedEvents(showRoomItem.id, associatedEvents));
  };
  return !events || !associatedEvents || eventsLoading ? (
    <Loading />
  ) : (
    <Accordion className={`text-start rounded-10 `}>
      <Accordion.Item className="card-body noBorder p-0 rounded-10" eventKey="0">
        <Accordion.Header className="rounded-10">
          <h2 className="mb-0 f24 fw-normal">Les événement associés</h2>
        </Accordion.Header>
        <Accordion.Body>
          <form className="d-flex flex-wrap justify-content-center">
            {events.map((el, index) => (
              <div className="form-check form-switch my-3 w-30" key={index}>
                <input
                  defaultChecked={associatedEvents.includes(el.id)}
                  className="form-check-input"
                  value={el.id}
                  type="checkbox"
                  id={el.id}
                  onChange={(e) => {
                    if (e.target.checked) {
                      associatedEvents.push(parseInt(e.target.value));
                    } else {
                      const i = associatedEvents.indexOf(parseInt(e.target.value));
                      if (i > -1) {
                        associatedEvents.splice(i, 1);
                      }
                    }
                  }}
                />
                <label className="form-check-label" htmlFor={el.id}>
                  {el.attributes.title}
                </label>
              </div>
            ))}
          </form>
          <div className="my-3">
            <CustomButton disabled={showRoomsLoading} buttonText={showRoomsLoading ? <LoadingButton /> : "Enregistrer les événements associés"} callBack={handleExposedModels} />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ShowRoomAssociatedEvents;
