import React, { useEffect, useState } from "react";
import { imageSrc } from "../../utils/utils";
import "./FileCard.css";
import "./FileCardSelector.css";

const FileCardSelector = ({
  file,
  name,
  handleImage,
  selected,
  setselected,
}) => {
  const [isVideo, setisVideo] = useState(false);
  const [isDocument, setisDocument] = useState(false);

  useEffect(() => {
    if (file.attributes.mime.includes("video")) {
      setisVideo(true);
    } else if (file.attributes.mime.includes("application")) {
      setisDocument(true);
      setisVideo(false);
    } else {
      setisVideo(false);
    }
  }, []);

  return (
    <div
      className="fileCard d-flex justify-content-center align-items-center"
      onClick={() => {
        handleImage({ attributes: file.attributes, id: file.id });
        setselected(file.id);
      }}
    >
      <div className="fileCardSelectIcon">
        {selected === file.id ? (
          <i className="bi f26 bi-check2-square"></i>
        ) : (
          <i className="bi f26 bi-square"></i>
        )}
      </div>
      <div className="fileCardFilter"></div>
      {isDocument ? (
        <i className="bi f60 bi-file-earmark-richtext-fill"></i>
      ) : isVideo ? (
        <video controls width="100%">
          <source src={imageSrc(file.attributes.url)} type="video/mp4" />

          <source src={imageSrc(file.attributes.url)} type="video/webm" />
        </video>
      ) : (
        <img
          className="fileCardImage"
          src={imageSrc(
            file.attributes.formats?.thumbnail?.url || file.attributes.url
          )}
          alt={imageSrc(file.attributes.formats?.url)}
        />
      )}
      <div className="fileCardImageName w-80 m-a">{name}</div>
    </div>
  );
};

export default FileCardSelector;
