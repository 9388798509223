import { Link } from "react-router-dom";
import { END_POINT } from "../Api/EndPoints.js";

export const otherLanguage = (lang) => {
  if (lang === "fr") {
    return "en";
  } else {
    return "fr";
  }
};

export const bgImage = (image) => {
  return image?.url
    ? `url(${END_POINT}${image.url})`
    : `url(${END_POINT}${encodeURI(image?.attributes?.url)})`;
};

export const imageSrc = (image) => {
  return `${END_POINT}${encodeURI(image)}`;
};

export const BreadCrumb = (company, path, whichType) => {
  const pathArray = path.split("/");

  switch (pathArray[1]) {
    case "":
      return (
        <span>
          <span className="text-grey">{company}</span> / Dashboard
        </span>
      );
    case "home":
      return (
        <span>
          <span className="text-grey">{company}</span> / Page Home
        </span>
      );
    case "blog":
      if (pathArray[2]) {
        return (
          <span>
            <span className="text-grey">{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Blog</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className="text-grey">{company}</span> / Blog
          </span>
        );
      }
    case "events":
      return (
        <span>
          <span className="text-grey">{company}</span> / Evenements
        </span>
      );
    case "event":
      if (pathArray[2]) {
        return (
          <span>
            <span className="text-grey">{company}</span> /{" "}
            <Link to={"/" + pathArray[1] + "s"}>Evenement</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className="text-grey">{company}</span> / Evenement
          </span>
        );
      }
    case "pages":
      if (pathArray[2]) {
        return (
          <span>
            <span className="text-grey">{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Pages</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className="text-grey">{company}</span> / Pages
          </span>
        );
      }

    case "products":
      if (pathArray[2]) {
        return (
          <span>
            <span className="text-grey">{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Produits</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className="text-grey">{company}</span> / Produits
          </span>
        );
      }
    case "showroom":
      if (pathArray[2]) {
        return (
          <span>
            <span className="text-grey">{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Revendeur</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className="text-grey">{company}</span> / Revendeur
          </span>
        );
      }
    case "contact":
      if (pathArray[2]) {
        return (
          <span>
            <span className="text-grey">{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Contacts</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className="text-grey">{company}</span> / Contacts
          </span>
        );
      }
    case "files":
      return (
        <span>
          <span className="text-grey">{company}</span> / Fichiers
        </span>
      );
    case "account":
      return (
        <span>
          <span className="text-grey">{company}</span> / Mon Compte
        </span>
      );

    default:
      break;
  }
};

export const pageLink = (pageName) => {
  switch (pageName) {
    case "Home Page":
      return "/";
    case "Page Avantages":
      return "/nos-avantages";

    case "Page Histoire":
      return "/notre-histoire";

    case "Page SAV":
      return "/garantie-sav";

    case "Page Nos réalisations":
      return "/nos-realisations";

    case "Page Nos Gammes Spa":
      return "/nos-gammes-spa";

    case "Page Nos Gammes Spa de Nage":
      return "/nos-gammes-spa-de-nage";

    case "Page Nos Gammes Sauna":
      return "/nos-gammes-sauna";

    case "Page Revendeur":
      return "/magasins";

    case "Page Evenements":
      return "/nos-evenements";

    case "Page Nous Rejoindre":
      return "/joindre-notre-reseau";

    case "Page Therapie":
      return "/therapie-par-poseidon";

    case "Page Blog":
      return "/nos-articles";

    case "Page Spa Divine Line":
      return "/gamme/spa-divine-line";
    case "Page Spa Premium Line":
      return "/gamme/spa-premium-line";

    case "Page Spa Sublime Line":
      return "/gamme/spa-sublime-line";

    case "Page Classic Line":
      return "/gamme/spa-divine-line";

    case "Page Spa de Nage River Jet":
      return "/gamme/spa-de-nage-river-jet";

    case "Page Page Spa de Nage Turbine":
      return "/gamme/spa-de-nage-turbine";

    case "Page Sauna Finlandais":
      return "/gamme/sauna-finlandais";

    case "Page Sauna Infrarouge":
      return "/gamme/sauna-infrarouge";

    default:
      return "/";
  }
};

export const productUrlRewrite = (id, name) => {
  return (
    id +
    "-" +
    name
      .replace(/ - /g, "-")
      .replace(/ & /g, "-")
      .replace(/ /g, "-")
      .replace(/\//g, "-")
      .replace(/é|è|ê|ë/g, "e")
      .replace(/à|ã|â|ä/g, "a")
      .replace(/ï|î|ì/g, "i")
      .replace(/ò|ô|ö|õ/g, "o")
      .replace(/û|ù|ü/g, "u")
      .replace(/ÿ/g, "y")
      .toLowerCase()
  );
};

export const interested = (code) => {
  switch (code) {
    case "0":
      return "Spa";
    case "1":
      return "Spa de nage";
    case "2":
      return "Sauna";

    default:
      return "Spa";
  }
};

export const contactColor = (contact) => {
  switch (contact.attributes.type) {
    case "Contact Rapide":
      return "lightGrey";
    case "Contact":
      return "transRed";
    case "Devis":
      return "transOrange";
    case "Catalogue":
      return "transPrimary";

    default:
      break;
  }
};

export const sortFunction = (arr, champs, asc) => {
  return arr.sort((a, b) =>
    champs === "id"
      ? asc
        ? a.id?.localeCompare(b.id, "fr", { numeric: true })
        : b.id?.localeCompare(a.id, "fr", { numeric: true })
      : asc
      ? a.attributes[champs]
          ?.toString()
          ?.localeCompare(b.attributes[champs]?.toString(), "fr", {
            numeric: true,
          })
      : b.attributes[champs]
          ?.toString()
          ?.localeCompare(a.attributes[champs]?.toString(), "fr", {
            numeric: true,
          })
  );
};
