import i18next from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { pageLink } from "../../utils/utils";
import CustomButton from "../Common/CustomButton";
import Loading from "../Common/Loading";

const PageList = ({ pagesLoading, pagesData, FRONT_SITE_URL }) => {
  const navigate = useNavigate();

  const goToPage = (page) => {
    navigate(`/pages/${page.id}`, {
      state: {
        id: page.id,
        idTrans: page.attributes.localizations?.data[0]?.id,
        enterWithLanguage: i18next.language,
      },
    });
  };

  const viewInSite = (goTo) => {
    window.open(FRONT_SITE_URL + goTo);
  };
  const viewLanding = (goTo) => {
    window.open(goTo);
  };

  return pagesLoading || !pagesData ? (
    <Loading />
  ) : (
    <div className="bg-white rounded-10 container-fluid p-5 shadowed">
      <div className="row f24 align-items-center">Liste des pages</div>
      <div className="my-4">
        {pagesData.map((page, index) => (
          <div
            key={index}
            className={`w-98 mx-auto my-2 row py-4 align-items-center justify-content-between rounded-10 ${
              index % 2 === 0 ? "bg-lightGrey" : ""
            }`}
          >
            <div className="col-8 text-start f20">{page?.attributes.title}</div>
            <div className="col-2">
              <CustomButton
                buttonText={"Modifier"}
                type="primary"
                iconStart="pencil-fill"
                callBack={() => goToPage(page)}
              />
            </div>

            <div className="col-2">
              <CustomButton
                buttonText={"Consulter"}
                type="primary"
                outlined
                iconStart="eye-fill"
                callBack={() =>
                  !page.attributes.landing
                    ? viewInSite(pageLink(page.attributes.title))
                    : viewLanding(
                        page.attributes.url ||
                          page.attributes.seo.data.attributes.url
                      )
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageList;
