import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../REDUX/actions/confirmationModal";
import CustomButton from "../Common/CustomButton";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { Form } from "react-bootstrap";
import { deleteEvent, publishOrNoPublishEvent } from "../../REDUX/actions/events";

const EventCard = ({ event, index }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteEvent(event.id));
    if (event.attributes.localizations.data[0]) {
      dispatch(deleteEvent(event.attributes.localizations.data[0].id));
    }
  };

  const showModalDelete = () =>
    dispatch(openModal({ type: "delete", confirmButtonText: "Supprimer", cancelButton: true, message: "Êtes-vous sûr(e) de vouloir supprimer l'événement  ?", callBack: handleDelete }));

  const showModalPublish = (checked) => {
    dispatch(
      openModal({
        confirmButtonText: checked ? "Publier" : "Retirer",
        cancelButton: true,
        message: checked ? "Êtes-vous sûr(e) de vouloir publier l'événement ?" : "Êtes-vous sûr(e) de vouloir rendre l'événement non publié ?",
        callBack: () => handlePublish(checked),
      })
    );
  };

  const goTo = () => {
    navigate(`/event/${event.id}`, { state: { id: event.id, idTrans: event.attributes.localizations.data[0].id, enterWithLanguage: i18n.language } });
  };
  const handlePublish = (checked) => dispatch(publishOrNoPublishEvent(event.id, checked));

  return (
    <div className={`w-98 mx-auto my-2 row py-4 align-items-center rounded-10 ${index % 2 === 0 ? "bg-lightGrey" : ""}`}>
      <div className="col-4 text-start">{event?.attributes.title}</div>

      <div className="col-2 text-start" key={event.id}>
        <Form.Check
          className="me-2"
          type="switch"
          checked={event.attributes.publish}
          onChange={(e) => {
            e.preventDefault();
            showModalPublish(e.target.checked);
          }}
        />
      </div>
      <div className="col-6 text-start row justify-content-start">
        <div className="col-4">
          <CustomButton buttonText={"Modifier"} iconStart="pencil-fill" callBack={goTo} />
        </div>
        <div className="col-4">
          <CustomButton buttonText={"Supprimer"} type="red" iconStart="trash3-fill" callBack={showModalDelete} />
        </div>
        <div className="col-4">{/* <CustomButton buttonText={"Supprimer"} type="red" iconStart="trash3-fill" callBack={showModalDelete} /> */}</div>
      </div>
    </div>
  );
};

export default EventCard;
