import i18next from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Common/Loading";
import PageCard from "../../Components/PageCard/PageCard";
import ProductList from "../../Components/Products/ProductList";
import { getPageByName } from "../../REDUX/actions/pages";
import { getAllProducts } from "../../REDUX/actions/products";

const ProductsView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPageByName("Page Nos réalisations"));
    dispatch(getAllProducts()); // eslint-disable-next-line
  }, [i18next.language]);

  const { pagesLoading, ActivePage } = useSelector((state) => state.pagesReducer);

  return pagesLoading || !ActivePage ? (
    <Loading />
  ) : (
    <div>
      <PageCard page={ActivePage} collapsed />
      <ProductList />
    </div>
  );
};

export default ProductsView;
