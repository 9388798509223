import i18next from "i18next";
import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loading from "../Common/Loading";

const RelatedProducts = ({ productItem, relatedProducts, setrelatedProducts, children }) => {
  const { productsLoading, products } = useSelector((state) => state.productsReducer);

  useEffect(() => {
    if (productItem) {
      setrelatedProducts(productItem.attributes.relatedProducts.data.map((el) => el.id));
    }
    // eslint-disable-next-line
  }, [productItem, i18next.language]);

  const itemStyle = "card-body noBorder mb-4 p-0 rounded-10";
  const headerStyle = "mb-0 f24 fw-normal text-orange";
  return !products || !relatedProducts || productsLoading ? (
    <Loading />
  ) : (
    <Accordion.Item className={itemStyle} eventKey="11">
      <Accordion.Header>
        <h2 className={headerStyle}>Les produits reliés</h2>
      </Accordion.Header>
      <Accordion.Body>
        <form className="d-flex flex-wrap justify-content-center">
          {products.map((el, index) =>
            el.id !== productItem.id ? (
              <div className="form-check text-start form-switch my-3 w-30" key={index}>
                <input
                  key={Math.random()}
                  defaultChecked={relatedProducts.includes(el.id)}
                  className="form-check-input"
                  value={el.id}
                  type="checkbox"
                  id={el.id}
                  onChange={(e) => {
                    if (e.target.checked) {
                      relatedProducts.push(parseInt(e.target.value));
                    } else {
                      const i = relatedProducts.indexOf(parseInt(e.target.value));
                      if (i > -1) {
                        relatedProducts.splice(i, 1);
                      }
                    }
                  }}
                />
                <label className="form-check-label" htmlFor={el.id}>
                  {el.attributes.name}
                </label>
              </div>
            ) : null
          )}
        </form>
        <div className="my-3">{children} </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default RelatedProducts;
