import React from "react";
import "./Drawer.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Drawer = () => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload(false);
  };
  const { user } = useSelector((state) => state.usersReducer);
  const location = useLocation();

  const onglets = [
    {
      name: "Dashboard",
      icon: "bi-columns-gap",
      to: "/",
      showOnlyToAdmin: false,
    },

    {
      name: "Blog",
      icon: "bi-file-earmark-fill",
      to: "/blog",
      showOnlyToAdmin: false,
    },
    {
      name: "Evénements",
      icon: "bi-calendar-event",
      to: "/events",
      showOnlyToAdmin: false,
    },
    {
      name: "Pages",
      icon: "bi-file-earmark-code",
      to: "/pages",
      showOnlyToAdmin: false,
    },
    {
      name: "Landing pages",
      icon: "bi-file-earmark",
      to: "/landing",
      showOnlyToAdmin: false,
    },

    {
      name: "Produits",
      icon: "bi-shop",
      to: "/products",
      showOnlyToAdmin: false,
    },
    {
      name: "Revendeur",
      icon: "bi-pin-map",
      to: "/showroom",
      showOnlyToAdmin: false,
    },
    {
      name: "Contacts",
      icon: "bi-envelope-fill",
      to: "/contact",
      showOnlyToAdmin: false,
    },
    {
      name: "Fichiers",
      icon: "bi-card-image",
      to: "/files",
      showOnlyToAdmin: false,
    },
    {
      name: "Utilisateurs",
      icon: "bi-people-fill",
      to: "/users",
      showOnlyToAdmin: !user.isAdmin,
    },
    {
      name: "Mon Compte",
      icon: "bi-person-circle",
      to: "/account",
      showOnlyToAdmin: false,
    },
  ];
  return (
    <div className="drawerContainer">
      <Link to={"/"} className="logoContainer mx-2"></Link>
      <div className="logoNavigations">
        {onglets.map((el, index) =>
          !el.showOnlyToAdmin ? (
            <Link
              to={el.to}
              className={`drawLink ${
                location.pathname === el.to ? "inpage" : ""
              }`}
              key={index}
            >
              <i className={`bi ${el.icon} ms-4 my-2 f26`}></i>{" "}
              <div>{el.name}</div>
            </Link>
          ) : null
        )}
      </div>
      <div className="logout f14 mb-2" onClick={handleLogout}>
        <div className="d-flex drawLink align-items-center ">
          <i className="bi bi-power ms-4 f26"></i> Déconnexion
        </div>
      </div>
    </div>
  );
};

export default Drawer;
